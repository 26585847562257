import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'

import Security from '../../commons/security/Security.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import CombustiblesAdjuntos from './CombustiblesAdjuntos'
import swal from 'sweetalert2'
import { FormattedMessage, injectIntl } from 'react-intl'
import backendStrings from '../../lang/backendStrings.js'
import Timezone from '../../commons/timezone/Timezone.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class CombustiblesAbm extends Component {
    constructor(props) {
        super(props);
        this.ajaxHandler = new AjaxHandler();


        moment.locale('es');

        this.state = {
            redirectTo: null,
            props: this.props,
            formData: {
                id: 0,
                movil: '',
                fecha_hora_carga: moment(),
                odometro: null,
                odometroAnterior: null,
                precio_litro: null,
                combustibleTipo: null,
                comentarios: '',
                bandera: null,
                importe: null,
                litros: null,
                tarjeta: null,
                adjuntos: []
			},
			tiposCombustible: [],

			errors: [],
			loading: false,
            odometroAnterior : 0,
			banderas: [],
			banderasLoading: false,
			combustibleTipos: [],
			combustibleTipoLoading: false,
			moviles: [],
			direccionForm: null,
			mostrarHora: true,
			ubicacionObligatorio: ConfigBusiness.get('combustibles.ubicacion.obligatorio')==='true' ? true : false
		};

		this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
		this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'formData.movil': (value) => Validator.notEmpty(value),
                'formData.odometro': (value) => Validator.conditionalFloatNunmber(value, this.state.formData['odometro'] && this.state.formData['odometro'] !== null && this.state.formData['odometro'] !== ''),
                //'formData.odometro': (value) => Validator.intNumberGreaterOrEqualThan(parseInt(value), parseInt(this.state.odometroAnterior)),
                'formData.precio_litro': (value) => Validator.floatNunmber(value),
                'formData.fecha_hora_carga': (value) => Validator.notEmpty(value),
                'formData.litros': (value) => Validator.notEmpty(value),
                'formData.litros': (value) => Validator.intNumberGreaterOrEqualThan(parseInt(value), 0),
                'formData.importe': (value) => Validator.notEmpty(value),
                'formData.importe': (value) => Validator.intNumberGreaterOrEqualThan(parseInt(value), 0),
                
            }
        });

    }

    initForm() {
        this.setState({ loading: true });
        let component = this;

        Promise.all([

            this.ajaxHandler.getJson('/combustible-tipos/select'),
            this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/combustibles/' + this.state.props.match.params.id) : null,
            this.ajaxHandler.getJson('/moviles/select-with-base-filtered-by-user'),
            this.ajaxHandler.getJson('/combustible-banderas/select'),

        ]).then((data) => {

            let combustibleTipos = data[0];
            let formData = data[1];
            let moviles = data[2];
            let banderas = data[3];

            component.setState({
                moviles: moviles,
                banderas: banderas,
                combustibleTipos: combustibleTipos

            });

            if (formData) {
                if (formData.fecha_hora_carga) {
                    formData.fecha_hora_carga = Timezone.getDateForClient(formData.fecha_hora_carga, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
                }
                //guardo los datos para el componente de direccion
                let direccionParams = {
                    calle: formData.direccion_informada,
                    numero: formData.numero_direccion_informada,
                    localidad: formData.localidad_informada,
                    provincia: formData.provincia_informada,
                    pais: formData.provincia_informada ? formData.provincia_informada.pais : null,
                    latitud: formData.latitud_informada,
                    longitud: formData.longitud_informada
                };

                formData.calle = formData.direccion_informada;
                formData.numero = formData.numero_direccion_informada;
                formData.localidad = formData.localidad_informada;
                formData.provincia = formData.provincia_informada;
                formData.pais = formData.provincia_informada ? formData.provincia_informada.pais : null;
                formData.latitud = formData.latitud_informada;
                formData.longitud = formData.longitud_informada;

                formData.movil = formData.movil_comb;

                formData.combustibleTipo = formData.combustible_tipo ? { value: formData.combustible_tipo.id, label: formData.combustible_tipo.descripcion } : null;
                formData.bandera = formData.bandera ? { value: formData.bandera.id, label: formData.bandera.descripcion } : null;
                formData.comentarios = formData.observaciones ? formData.observaciones : null;
                formData.adjuntos = formData.adjuntos ? formData.adjuntos : [];

                component.setState({
                    formData: formData,
                    direccionForm: direccionParams
                });
            }

            if (this.state.props.action === 'EDIT') {
                if (formData['provincia']) this.handlePaisChange({ value: formData['provincia'].pais.id, label: formData['provincia'].pais.nombre })
                    .then(() => {

                        if (formData['provincia']) this.handleProvinciaChange({ value: formData['provincia'].id, label: formData['provincia'].nombre })

                    });

            }

        }).catch(function(error) {
            console.log(error);
            component.exit();
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    componentWillUnmount() {
        this.ajaxHandler.unsubscribe();
    }
    componentWillMount() {

        if ((Security.hasPermission('COMBUSTIBLES_CREAR') && this.state.props.action === 'ADD') ||
            (Security.hasPermission('COMBUSTIBLES_MODIFICAR') && this.state.props.action === 'EDIT') ||
            (Security.hasPermission('COMBUSTIBLES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
            this.ajaxHandler.subscribe(this);
            this.initForm();
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }

    }

    getData(service, param) {
        let serviceURL = "";
        if (service === "provincias" || service === "localidades") {
            serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param + '/select') : (Config.get('apiUrlBase') + '/' + service);
        } else {
            serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
        }
        return fetch(serviceURL, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization-Token': localStorage.getItem("token")
                }
            })
            .then(response => {
                return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
            });
    }


    handleAdjuntosChange(adjuntos) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.adjuntos = adjuntos;
        this.setState({
            formData: formDataCopy
        });
    }


    handleEstadoChange(activo) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['activo'] = activo;
        this.setState({
            formData: formDataCopy
        });
    }

    handleEsUsuarioChange(esUsuario) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['esUsuario'] = esUsuario;
        this.setState({
            formData: formDataCopy
        });
    }

    handleNotificacionesActivasChange(notificacionesActivas) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
        this.setState({
            formData: formDataCopy
        });
    }

    handleInputFormChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = value !== '' ? value : (target.type === 'textarea' ? '' : null);
        if ((name === 'importe' || name === 'litros') && formDataCopy['importe'] && formDataCopy['litros']) {
            formDataCopy['precio_litro'] = (formDataCopy['importe'] / formDataCopy['litros']).toFixed(2);
        }
        this.setState({
            formData: formDataCopy
        });
    }

    handleBasesChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario[name] = value;
        this.setState({
            formData: formDataCopy
        });
    }


    handleUsuarioFormChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        //TODO: CHEQUEAR QUE EL USUARIO SEA ÜNICO LO MISMO CON EL NRO. DE LEGAJO
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario[name] = value;
        this.setState({
            formData: formDataCopy
        });
    }

    handleSelectFormChange(name, object) {

        const value = object === null ? null : object;

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = value;

        this.setState({
            formData: formDataCopy
        });
    }

    handleMovilChange(object) {
        let component = this;
        if (object) {
            this.setState({ loading: true });
            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy.movil = { id: object.value, dominio: object.label };
            this.setState({
                formData: formDataCopy
            }, () => {
                this.setState({ loading: true });
                Promise.all([
                    this.ajaxHandler.getJson('/moviles/' + object.value + '/' + moment(this.state.formData.fecha_hora_carga).format('YYYY-MM-DD HH:mm:ss') + '/km')
                ]).then((data) => {
                    formDataCopy.odometroAnterior = data[0];
                    let odomAnterior;
                    if (data[0] == null ){
                        odomAnterior = 0
                     } else {
                        odomAnterior = formDataCopy.odometroAnterior.km
                     }
                    this.setState({
                        odometroAnterior : odomAnterior,
                        formData: formDataCopy,
                        loading: false
                    });
                });
            });
        } else {
            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy.movil = null;
            formDataCopy.odometroAnterior = null;
            this.setState({
                formData: formDataCopy
            });
        }
    }

    handleDatePickerFormChange(name, event) {

        let nuevaFecha = "";
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        if (name === "horaActual" && event) {
            nuevaFecha = moment(moment(this.state.formData.fecha_hora_carga).format('L') + ' ' + event.format('LTS'), "DD-MM-YYYY hh:mm:ss a");
            formDataCopy['fecha_hora_carga'] = nuevaFecha;
        } else {
            formDataCopy['fecha_hora_carga'] = event;

        }
        let mostrarHora = this.state.mostrarHora;
        if ("fechaActual" && !event) {
            mostrarHora = false;
        } else {
            mostrarHora = true;
        }

        this.setState({
            formData: formDataCopy,
            mostrarHora: mostrarHora
        }, () => {
            if (this.state.formData.movil) {
                this.setState({ loading: true });
                this.ajaxHandler.getJson('/moviles/' + this.state.formData.movil.id + '/' + moment(this.state.formData.fecha_hora_carga).format('YYYY-MM-DD HH:mm:ss') + '/km')
                    .then((data) => {
                        formDataCopy.odometroAnterior = data;
                        this.setState({
                            formData: formDataCopy,
                            loading: false
                        });
                    });
            }
        });
    }

    handleDatePickerFormRawChange(name, date) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = date;
        this.setState({
            formData: formDataCopy
        });
    }


    handleProvinciaChange(object) {
        return new Promise((resolve, reject) => {
            if (object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.provincia = { id: object.value, label: object.label };
                this.setState({
                    formData: formDataCopy,
                }, () => {
                    resolve();
                });
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.provincia = null;
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    localidades: []
                }, () => resolve());
            }
        });
    }

    handleLocalidadChange(object) {
        return new Promise((resolve, reject) => {
            if (object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.localidad = { id: object.value, label: object.label };
                this.setState({
                    formData: formDataCopy
                }, () => resolve());
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy
                }, () => resolve());
            }
        });
    }

    handlePaisChange(object) {
        return new Promise((resolve, reject) => {
            if (object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.pais = { id: object.value, label: object.label };
                formDataCopy.provincia = null;
                this.setState({
                    formData: formDataCopy,
                    provincias: [],
                    localidades: []
                }, () => {
                    this.setState({ provinciasLoading: true });
                    this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
                        .then((data) => {
                            this.setState({
                                provincias: data
                            }, () => resolve());
                        }).finally(() => {
                            this.setState({ provinciasLoading: false });
                        });
                });
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.pais = null;
                formDataCopy.provincia = null;
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    provincias: [],
                    localidades: []
                }, () => resolve());
            }
        });
    }

    handleAgregarBandera() {
        let ajaxHandler = this.ajaxHandler;
        swal.fire({
            title: 'Bandera',
            input: 'text',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Debe ingresar una bandera';
                }
                value = value.toUpperCase();
                for (let i = 0; i < this.state.banderas.length; i++) {
                    if (this.state.banderas[i].label.toUpperCase() == value) {
                        return 'La bandera ya existe';
                    }
                }
            }
        }).then((result) => {
            if (result.value) {
                ajaxHandler.fetch('/combustible-banderas', {
                    method: 'POST',
                    body: JSON.stringify({
                        descripcion: result.value.toUpperCase()
                    }),
                }).then(response => response.json()
                    .then(nuevaBandera => {
                        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                        formDataCopy['bandera'] = nuevaBandera ? { value: nuevaBandera.id, label: nuevaBandera.descripcion } : null;
                        let banderasCopy = JSON.parse(JSON.stringify(this.state.banderas));
                        if (nuevaBandera) {
                            banderasCopy.push({ value: nuevaBandera.id, label: nuevaBandera.descripcion });
                        }
                        this.setState({
                            formData: formDataCopy,
                            banderas: banderasCopy,
                        });
                    })).catch((error) => {
                    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                    formDataCopy['bandera'] = null;
                    this.setState({
                        formData: formDataCopy
                    });
                })
            }
        })
    }

    handleChangeDireccion(dataDireccion, valueDireccion, direccionValid) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[dataDireccion] = valueDireccion;
        formDataCopy['direccionValid'] = direccionValid;
        this.setState({
            formData: formDataCopy
        });

    }

    handleSubmit(event) {
        //formatea la fecha antes de enviarla
        this.state.formData.fecha_hora_carga = moment(this.state.formData.fecha_hora_carga).format('YYYY-MM-DD HH:mm:ss');
        this.setState({ loading: true });
        let component = this
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.fecha_hora_carga = Timezone.getDateForClient(this.state.formData.fecha_hora_carga).format('YYYY-MM-DD HH:mm:ss');
        this.ajaxHandler.fetch('/combustibles' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
            method: this.props.action === 'ADD' ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...formDataCopy
            }),
        }).then(response => {
            if (response.status !== 400) {

                component.exit();
            } else {
                response.json()
                    .then(data => {
                        this.setState({
                            errors: data.detalle
                        });
                    });
            }
            window.scrollTo(0, 0);
        }).catch((error) => {
            console.log(error);
            component.ajaxHandler.handleError(error);
        }).finally(() => {
            this.setState({ loading: false });
        });
        event.preventDefault();
    }

    handleCancel(event) {
        this.exit();
    }

    exit() {
        this.setState({
            redirectTo: '/combustibles'
        });
    }

    render() {
        this.formValidation.validate();
        let formData = this.state.formData;
        let validationState = this.formValidation.state;

        let state = this.state;

        let requiredSymbol = this.state.props.action !== 'VIEW' ? ' *' : '';

        let diasOdometro = "";
        if (formData.odometroAnterior) {
            diasOdometro = moment().diff(moment(formData.odometroAnterior.fecha), 'days') + ' días';

            if (diasOdometro === "0 días") {
                diasOdometro = moment().diff(moment(formData.odometroAnterior.fecha), 'hours') + ' horas';
            }
        }

        return ( 
			<React.Fragment > 
				{state.redirectTo && < Redirect push to = { state.redirectTo }/>} { state.loading && < Loading /> } 
				<div className = "row" >
					<div className = "col-md-12" >
						<div className = "alert alert-danger"
							role = "alert"
							hidden = { state.errors.length === 0 } > 
							{ state.errors.map((e, i) => 
								< li key = { i } > < FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default']) }/></li>) 
							} 
				        </div> 
				        <form className = "form form-horizontal" ref = "form" onSubmit = { this.handleSubmit }>
					        <div className = "form-body" >
						        <div className = "card pull-up" >
							        <div className = "card-content" >
								        <div className = "card-body" >
									        <h4 className = "form-section" >
        										<i className = "icon-home" > </i>
										            <FormattedMessage id="combustiblesAbm.detalles_carga" defaultMessage=" Detalles de la Carga "/>
                                                <div className = "float-right"
                                                    style = {{ fontSize: '14px' } } > < FormattedMessage id = "combustiblesAbm.campos_requeridos" defaultMessage = "* campos requeridos"/> 				 
                                                </div>
                                            </h4> 
                                            <div className = "row" > { /* MOVIL */ } 
                                                <div className = "col-md-6" >
                                                    <div className = "form-group row" >
                                                        <label className = "col-md-3 label-control col-form-label" htmlFor = "base" >
                                                            <FormattedMessage id = "combustiblesAbm.detalles_carga.movil"                defaultMessage = "Movil" /> { requiredSymbol }:
                                                        </label>
                                                        <div className = "col-md-9" > 
                                                            {this.props.action === 'VIEW' ? ( 
                                                                <div className = "form-control-static col-form-label form-value" > 
                                                                    { formData.movil ? formData.movil.dominio : '' } 
                                                                </div>
                                                                ):(
                                                                <div>
                                                                    <Select id = "movil"
                                                                        name = "movil"
                                                                        placeholder = { this.props.intl.formatMessage({ id: 'combustiblesAbm.detalles_carga.movil.placeholder', defaultMessage: 'Móvil' }) }
                                                                        options = { this.state.moviles }
                                                                        valueKey = 'value'
                                                                        labelKey = 'label'
                                                                        value = { formData.movil ? { value: formData.movil.id, label: formData.movil.dominio } : null }
                                                                        onChange = {(e) => this.handleMovilChange(e) }
                                                                    />
                                                                </div>
                                                                )
                                                            }
                                                        </div> 
                                                    </div> 
                                                </div> 
                                            </div> 
                                            <div className = "row" > { /* FECHA DE CARGA*/ } 
                                                <div className = "col-md-3">
                                                    <div className = "form-group row" >
                                                        <label className = "col-md-6 label-control col-form-label" htmlFor = "fechaActual" >
                                                            <FormattedMessage id = "combustiblesAbm.detalles_carga.fecha" defaultMessage = "Fecha" /> { requiredSymbol }:
                                                        </label> 
                                                        <div className = "col-md-6" > 
                                                            {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('COMBUSTIBLES_MODIFICAR')) ? ( 
                                                                <div className = "form-control-static col-form-label form-value" > 
                                                                    { formData.fecha_actual === null || formData.fecha_actual === '0000-00-00' ? '' : moment(formData.fecha_hora_carga).format('L') }
                                                                </div>
                                                                ):( 
                                                                <div>
                                                                    <DatePicker id = "fechaActual"
                                                                        name = "fechaActual"
                                                                        className = "form-control date-picker-placeholder"
                                                                        selected = { formData.fecha_hora_carga === null || formData.fecha_hora_carga === '0000-00-00' ? null : moment(formData.fecha_hora_carga) }
                                                                        placeholderText = { this.props.intl.formatMessage({ id: 'combustiblesAbm.detalles_carga.fecha.placeholder', defaultMessage: 'DD/MM/AAAA' }) }
                                                                        onChange = {
                                                                            (event) => this.handleDatePickerFormChange("fechaActual", event) }
                                                                        onChangeRaw = {
                                                                            (event) => this.handleDatePickerFormRawChange("fechaActual", event.target.value) }
                                                                        maxDate = { moment() }
                                                                        dateFormat = "L" 
                                                                    /> { /*<div className="help-block text-danger field-message" hidden={validationState.formData.fechaActual.pristine || validationState.formData.fechaActual.valid}>{validationState.formData.fechaAlta.message}</div>*/ } 
                                                                </div>
                                                                )
                                                            }
                                                        </div> 
													</div>
                                                </div> 
                                                 { /* HORA DE ALTA */ } 
                                                <div className = "col-md-3" hidden = {!state.mostrarHora } >
                                                    <div className = "form-group row" >
                                                        <label className = "col-md-6 label-control col-form-label" htmlFor = "horaActual" >
                                                            <FormattedMessage id = "combustiblesAbm.detalles_carga.hora" defaultMessage = "Hora" /> { requiredSymbol }:
                                                        </label> 
                                                        <div className = "col-md-6" > 
                                                            {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? ( 
                                                                <div className = "form-control-static col-form-label form-value" > 
                                                                    { formData.hora_actual === null || formData.hora_actual === '00:00' ? '' : moment(formData.fecha_hora_carga, 'YYYY-MM-DD HH:mm:ss').format('h:mm a') } 
                                                                </div>
                                                                ):( 
                                                                <div>
																	<DatePicker id = "horaActual"
																		name = "horaActual"
																		className = "form-control date-picker-placeholder"
																		selected = { formData.fecha_hora_carga === null || formData.fecha_hora_carga === '00:00' ? null : moment(formData.fecha_hora_carga) }
																		onChange = {
																			(event) => this.handleDatePickerFormChange("horaActual", event) }
																		onChangeRaw = {
																			(event) => this.handleDatePickerFormRawChange("horaActual", event.target.value) }
																		showTimeSelect showTimeSelectOnly timeIntervals = { 10 }
																		dateFormat = "h:mm a"
																		timeCaption = { this.props.intl.formatMessage({ id: 'combustiblesAbm.detalles_carga.hora.timeCaption', defaultMessage: 'Hora' }) }
																	/> { /*<div className="help-block text-danger field-message" hidden={validationState.formData.hora_actual.pristine || validationState.formData.hora_actual.valid}>{validationState.formData.hora_actual.message}</div>*/ } 
                                                                </div>
                                                                )
                                                            } 
														</div>
                                                    </div> 
                                                </div> 
                                            </div> 
                                        
							
											<div className = "row" > { /* ODÓMETRO */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "odometro" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.odometro" defaultMessage = "Odómetro:" />
														</label> 
														<div className = "col-md-9" > 
															{this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? ( 
															<div className = "form-control-static col-form-label form-value" > 
																{ formData.odometro ? formData.odometro : '' } 
															</div>
															) : ( 
																<div>
																	<input type = "text"
																	className = "form-control"
																	id = "odometro"
                                                                    min="0"
																	name = "odometro"
																	placeholder = ""
																	value = { formData.odometro ? formData.odometro : '' }
																	onChange = { this.handleInputFormChange }
																/> 
																<div className = "help-block field-message" > 
																	{ formData.odometroAnterior ? 'Ultima Actualización: ' + formData.odometroAnterior.km + 'km (Usuario: ' + formData.odometroAnterior.usuario + ' hace ' + diasOdometro + ')' : '' } 
																</div> 
																<div className = "help-block text-danger field-message"
																	hidden = {!validationState.formData.odometro.valid || this.state.formData.odometro == null || this.state.formData.odometroAnterior == null || (parseInt(this.state.formData.odometro)) < (parseInt(this.state.formData.odometroAnterior.km)) || Math.abs(parseFloat(this.state.formData.odometro) - parseFloat(this.state.formData.odometroAnterior.km)) <= 1000 } 
																> 
																	< FormattedMessage id = "combustiblesAbm.detalles_carga.odometro.information.odometro_supera_lectura_anterior" defaultMessage = "El odómetro supera en + de 1000km a la lectura anterior" /> 
																</div> 
																{ /*<div className = "help-block text-danger field-message"
																	hidden = { validationState.formData.odometro.pristine || validationState.formData.odometro.valid } >
																		< FormattedMessage id = "combustiblesAbm.detalles_carga.odometro.information.numero_decimal_Ej" defaultMessage = "Número decimal Ej: 2.31" /> 
																</div>*/ } 
                                                                <div className = "help-block text-danger field-message"
																	    hidden = {(parseFloat(this.state.formData.odometro)) >= 0  || this.state.formData.odometro == undefined || this.state.formData.odometro == '' }>
                                                                        <FormattedMessage id = "combustiblesAbm.detalles_carga.odometro.information.numero_negativo" 
                                                                        defaultMessage = "El valor ingresado está en negativo, por favor ingresar un número válido"/>
																    </div>
                                                                    { /*<div className = "help-block text-danger field-message"
                                                                        hidden = { this.state.formData.odometro == null || this.state.formData.odometroAnterior == null || (parseFloat(this.state.formData.odometro)) >= (parseFloat(this.state.formData.odometroAnterior.km)) }>
                                                                        <FormattedMessage id = "combustiblesAbm.detalles_carga.odometro.information.odometro_menor_anterior" 
                                                                        defaultMessage = "El odómetro tiene que ser mayor al que posee actualmente el movil"/>
                                                                    </div>*/ } 
                                                                    
															</div>					  
															)
														} 
														</div>
													</div> 
												</div> 
											</div>
											<div className = "row" > { /* LITROS */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "litros" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.litros" defaultMessage = "Litros *:" />
														</label> 
														<div className = "col-md-9" > 
															{this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? ( 
																<div className = "form-control-static col-form-label form-value" > 
																	{ formData.litros ? formData.litros : '' } 
																</div>
																) : ( 
																<div>
																	<input type = "text"
																	className = "form-control"
																	id = "litros"
                                                                    min="0"
																	name = "litros"
																	placeholder = ""
																	value = { formData.litros ? formData.litros : '' }
																	onChange = { this.handleInputFormChange }
																	/> 
																	{/*<div className = "help-block text-danger field-message"
																		hidden = { validationState.formData.litros.pristine || validationState.formData.litros.valid } > 
                                                                        < FormattedMessage id = "combustiblesAbm.detalles_carga.litros.information.numero_decimal_Ej"
																		defaultMessage = "Número decimal Ej: 2.31" /> 
																	</div>*/}
                                                                    <div className = "help-block text-danger field-message"
																	    hidden = {(parseFloat(this.state.formData.litros)) > 0 || this.state.formData.litros == undefined || this.state.formData.litros == ''}>
                                                                        <FormattedMessage id = "combustiblesAbm.detalles_carga.litros.information.numero_negativo" 
                                                                        defaultMessage = "El valor ingresado está en negativo, por favor ingresar un número válido"/>
																    </div> 
																</div>
																)
															} 
														</div> 
													</div> 
												</div> 
											</div> 
											<div className = "row" > { /* IMPORTE */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "importe" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.importe" defaultMessage = "Importe *:" />
														</label> 
														<div className = "col-md-9" > 
															{this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? ( 
																<div className = "form-control-static col-form-label form-value" > 
																	{ formData.importe ? formData.importe : '' } 
																</div>
															) : ( 
																<div>
																<input type = "text"
																className = "form-control"
																id = "importe"
                                                                min="0"
																name = "importe"
																placeholder = ""
																value = { formData.importe ? formData.importe : '' }
																onChange = { this.handleInputFormChange }
																/> 
																{/*<div className = "help-block text-danger field-message" hidden = { validationState.formData.importe.pristine || validationState.formData.importe.valid } > 
																	<FormattedMessage id = "combustiblesAbm.detalles_carga.importe.information.numero_decimal_Ej" defaultMessage = "Número decimal Ej: 2.31" /> 
																</div>*/}
                                                                <div className = "help-block text-danger field-message"
																	    hidden = {(parseFloat(this.state.formData.importe)) >= 0  || this.state.formData.importe == undefined || this.state.formData.importe == '' }>
                                                                        <FormattedMessage id = "combustiblesAbm.detalles_carga.importe.information.numero_negativo" 
                                                                        defaultMessage = "El valor ingresado está en negativo, por favor ingresar un número válido"/>
																</div>  
															</div>
															)
														} 
														</div> 
													</div> 
												</div> 
											</div> 
											<div className = "row" > { /* PRECIO/LITRO */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "precioLitro" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.precio_por_litro" defaultMessage = "Precio por Litro:" />
														</label> 
														<div className = "col-md-9" > 
															{this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? ( 
															<div className = "form-control-static col-form-label form-value"> 
																{ formData.precio_litro ? formData.precio_litro : '' } 
															</div>
															) : ( 
															<div>
																<input 
																	type = "text"
																	className = "form-control"
																	id = "precio_litro"
																	name = "precio_litro"
																	placeholder = ""
                                                                    readOnly
																	value = { formData.precio_litro ? formData.precio_litro : '' }
																	onChange = { this.handleInputFormChange }
																/> 
																<div className = "help-block text-danger field-message" hidden = { validationState.formData.precio_litro.pristine || validationState.formData.precio_litro.valid } >
																	<FormattedMessage id = "combustiblesAbm.detalles_carga.precio_por_litro.information.numero_decimal_Ej" defaultMessage = "Numero decimal Ej: 2.31" />
																</div> 
															</div>
															)
															} 
														</div> 
													</div> 
												</div> 
											</div> 
											<div className = "row" > { /* Tipo de Combustible */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "combustibleTipo" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.tipo_de_combustible" defaultMessage = "Tipo de Combustible:" />
														</label> 
														<div className = "col-md-9" > 
															{this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('COMBUSTIBLES_MODIFICAR')) ? ( 
																<div className = "form-control-static col-form-label form-value" > 
																	{ state.formData.combustibleTipo ? state.formData.combustibleTipo.label : '' } 
																</div>
															) : ( 
																<div>
																	<Select id = "combustibleTipo"
																	name = "combustibleTipo"
																	placeholder = {!state.combustibleTipoLoading ? this.props.intl.formatMessage({ id: 'combustiblesAbm.detalles_carga.tipo_de_combustible.placeholder', defaultMessage: 'Seleccione' }) : '' }
																	options = { state.combustibleTipos }
																	valueKey = 'value'
																	labelKey = 'label'
																	value = { formData.combustibleTipo ? formData.combustibleTipo : null }
																	onChange = {
																		(e) => this.handleSelectFormChange("combustibleTipo", e) }
																	isLoading = { state.combustibleTipoLoading }
																	disable = { state.combustibleTipoLoading }
																	/>
																</div>
															)
															} 
														</div> 
													</div> 
												</div> 
											</div> 
											<div className = "row" > { /* Tarjeta */ } 
												<div className = "col-md-6" >
													<div className = "form-group row" >
														<label className = "col-md-3 label-control col-form-label" htmlFor = "Tarjeta" >
															<FormattedMessage id = "combustiblesAbm.detalles_carga.tarjeta" defaultMessage = "Tarjeta:" />
														</label> 
														<div className = "col-md-9" >
														{ this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission ('COMBUSTIBLES_MODIFICAR')) ? (
															<div className = "form-control-static col-form-label form-value" > { state.formData.tarjeta } </div>
														) : (  
															<div >
																<input type = "text"
																className = "form-control"
																id = "tarjeta"
																name = "tarjeta"
																placeholder = ""
																value = { formData.tarjeta ? formData.tarjeta : '' }
																maxLength={50}
																onChange = { this.handleInputFormChange }/>
															</div>
														)}
														</div>
													</div>
												</div>
											</div>

                                        </div>
                                    </div>
                                </div>
                                
                                <div className = "card pull-up" >
                                    <div className = "card-content" >
                                        <div className = "card-body" id = "container-direccion" >
                                            <h4 className = "form-section mt-2" >
                                                <i className = "ft-map-pin" > </i>
                                                <FormattedMessage id="combustiblesAbm.ubicacion_carga" defaultMessage=" Ubicación de Carga"/>
                                            </h4> 
                                            <DireccionAutocompletar
                                                action = { this.props.action }
                                                camposHidden = {["codigoPostal"] }
                                                cambioDireccion = { this.handleChangeDireccion }
                                                formulario = { state.direccionForm }
                                                localidadString = { true }
                                                crearProvincias = { true }
                                                crearLocalidades = { true }
                                                camposObligatorios = {this.state.ubicacionObligatorio ? ["latitud", "longitud"] : [] }
                                            />

                                            <div className = "row" > { /* Bandera */ } 
                                                <div className = "col-md-6" >
                                                    <div className = "form-group row" >
                                                        <label className = "col-md-3 label-control col-form-label" htmlFor = "bandera" >
                                                            <FormattedMessage id = "combustiblesAbm.ubicacion_carga.bandera" defaultMessage = "Bandera:" />
                                                        </label> 
                                                        <div className = "col-md-9" > 
                                                            {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('COMBUSTIBLES_MODIFICAR')) ? ( 
                                                                <div className = "form-control-static col-form-label form-value" > 
                                                                    { state.formData.bandera ? state.formData.bandera.label : '' } 
                                                                </div>																				
                                                            ) : ( 
                                                                <div>
                                                                    <div className = "col-md-10" style = {{ display: "inline-table", padding: 0 + "px" } } >
                                                                        <Select id = "bandera"
                                                                        name = "bandera"
                                                                        placeholder = {!state.banderasLoading ? this.props.intl.formatMessage({ id: 'combustiblesAbm.ubicacion_carga.bandera.placeholder', defaultMessage: 'Seleccione' }) : '' }
                                                                        options = { state.banderas }
                                                                        valueKey = 'value'
                                                                        labelKey = 'label'
                                                                        value = { state.formData.bandera ? state.formData.bandera : null }
                                                                        onChange = {
                                                                            (e) => this.handleSelectFormChange("bandera", e) }
                                                                        isLoading = { state.banderasLoading }
                                                                        disable = { state.banderasLoading }
                                                                        /> 
                                                                    </div> 
                                                                    <div className = "col-md-1" style = {{ display: "inline-table", 'paddingRight': 0 + "px"}}>
                                                                        <button type = "button" className = "btn btn-primary" onClick = {() => this.handleAgregarBandera() } > + </button> 
                                                                    </div>
                                                                </div>
                                                            )
                                                            } 
                                                        </div> 
                                                    </div> 
                                                </div> 
                                            </div> 
                                        </div> 
                                    </div> 
                                </div>
                                {
                                    this.props.action === 'ADD' || (this.props.action !== 'ADD' && this.state.formData.id !== 0) ? ( 
                                    <CombustiblesAdjuntos 
                                        combustible = { this.state.formData }
                                        action = { this.props.action }
                                        callbackUpdate = { this.handleAdjuntosChange.bind(this) } > 
                                        </CombustiblesAdjuntos>
                                    ) : ''
                                }

                                <div className = "card pull-up" >
                                    <div className = "card-content" >
                                        <div className = "card-body" id = "container-direccion">
                                            <h4 className = "form-section mt-2" >
                                                <i className = "ft-map-pin" ></i>
                                                <FormattedMessage id="combustiblesAbm.comentarios" defaultMessage=" Comentarios"/>
                                            </h4>
                                            <div className = "row" > { /* OBSERVACIONES */ } 
                                                <div className = "col-md-6" >
                                                    <div className = "form-group row" >
                                                        <label className = "col-md-3 label-control col-form-label" htmlFor = "comentarios" >
                                                            Comentarios:
                                                        </label> 
                                                        <div className = "col-md-9" > 
															{this.props.action === 'VIEW' ? ( 
																<div className="form-control-static col-form-label" > 
																	{ formData.comentarios } 
																</div>
															) : ( 
																<div>
																	<textarea 
																		className = "form-control"
																		id = "comentarios"
																		name = "comentarios"
																		rows = "3"
																		placeholder = { this.props.intl.formatMessage({ id: 'combustiblesAbm.comentarios.placeholder', defaultMessage: 'Comentarios' }) }
																		value = { formData.comentarios }
																		onChange = { this.handleInputFormChange } > 
																	</textarea> 
																</div>
															)
                                                       		} 
                                                    	</div> 
                                                	</div> 
                                            	</div> 
                                        	</div> 
                                    	</div> 
                                	</div>
								</div> 
                            
                                <div className = "card pull-up" >
                                    <div className = "card-content" >
                                        <div className = "card-body" >
                                            <div className = "text-cd text-right" > 
                                                {this.props.action !== 'VIEW' && ( 
                                                    <button type = "submit" className = "btn btn-primary mr-1" disabled = {!validationState.form.valid || (this.state.ubicacionObligatorio ? (this.state.formData.direccionValid ? false : true) : false)}>
                                                        <i className = "fa fa-check-circle" > </i>
							                            <FormattedMessage id="combustiblesAbm.button.guardar" defaultMessage=" Guardar"/>
                                                    </button>
                                                )
                                                } 
                                                <button type = "button"	className = "btn btn-danger" onClick = { this.handleCancel.bind(this) } >
						                            <i className = "fa fa-times-circle" > </i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'combustiblesAbm.button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'combustiblesAbm.button.cancelar', defaultMessage: 'Cancelar' })} 
					                            </button> 
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
							</div>
							
			
                        </form> 
                    </div> 
                </div> 
            </React.Fragment>
        );
    }
}

export default injectIntl(CombustiblesAbm);
